// const baseURL = process.env.NODE_ENV === 'production' ? 'http://192.168.88.10' : 'http://10.0.4.189';

// export const config = {
//     URL_API_PRIVATE: `${baseURL}:9000/api`,
//     URL_API_PUBLIC: `${baseURL}:9000/api/public`,
//     URL_STATIC: `${baseURL}:9000/static`,
//     URL_SOCKET: 'http://192.168.88.15:3010',
//     URL_AGILDATA: 'https://panel.agildataonline.com/api/v1',
// }; 




const baseURL = process.env.NODE_ENV === 'production' ? 'https://thegroup.credifin.com.ar' : 'http://10.0.4.189:9000';

export const config = {
    URL_API_PRIVATE: `${baseURL}/api`,
    URL_API_PUBLIC: `${baseURL}/api/public`,
    URL_STATIC: `${baseURL}/static`,
    URL_SOCKET: 'http://10.12.250.22:3010',
    URL_AGILDATA: 'https://panel.agildataonline.com/api/v1',
};
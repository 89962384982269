import { AUTH_TOKEN } from "constants/auth"
import { TIPOS_DOMICILIOS } from "constants/demoData"
import dayjs from 'dayjs';
import { Tooltip, Typography, Modal } from 'antd';
import moment from "moment/moment";

const { Text } = Typography;
export const TOKEN = localStorage.getItem(AUTH_TOKEN)

export const generateElements = (length, fn) => {
  return Array.from({ length: length }).map(() => fn())
};

export const getRandomNumber = (min, max) => {
  return parseInt(Math.random() * (max - min) + min);
};

export const formatLocale = (value) => {
  return value?.toLocaleString("es-AR", { minimumFractionDigits: 2, maximumFractionDigits: 10 });
};

export const formatCurrency = (value) => {
  return `$${formatLocale(value)}`
};

export const formatPercentage = (value) => {
  return `${formatLocale(value)}%`
};

export const getListaTitulo = (articulo, data) => {
  return articulo + ' Desde ' + data.km + ' Km. - Desde ' + currency(data.kg) + ' kg.';
};

export const showErrorModal = ({
  title,
  err
}) => {
  Modal.error({
    title: title || "Error",
    content: (err && typeof err === "string") ? err : err?.message || JSON.stringify(err),
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validarUsuario = (e) => {
  let text = e.target.value;
  text = text.toLowerCase().replace(/\s/g, ''); // Sin espacios
  text = text.replace(/[^a-z0-9]/g, ''); // Permitir letras y números
  e.target.value = text;
};

export const normalizarTexto = e => {
  let texto = e.target.value
  // Eliminar símbolos y mantener solo letras y números
  texto = texto.replace(/[^a-zA-Z0-9\s]|(?<=\s)\s+/g, '');
  const enMayusculas = texto.toUpperCase()
  const sinTildes = eliminarTildes(enMayusculas)
  e.target.value = sinTildes
};

export const mayuscula = e => {
  let texto = e.target.value;
  const enMayusculas = texto.toUpperCase();
  const sinTildes = eliminarTildes(enMayusculas);
  e.target.value = sinTildes;
};

export const validarNumero = e => {
  // Eliminar cualquier carácter que no sea un número
  e.target.value = e.target.value.replace(/[^0-9]/g, '');
};

export const eliminarTildes = text => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
};

export const filterOption = (inputValue, option) => {
  const parsedOption = eliminarTildes(option.label.toLowerCase())
  const parsedInput = eliminarTildes(inputValue.normalize().toLowerCase())
  return parsedOption.includes(parsedInput)
};

export const filterTransferOption = (inputValue, option) => {
  const parsedOption = option.title.toLowerCase()
  const parsedInput = inputValue.normalize().toLowerCase()
  return parsedOption.includes(parsedInput)
};

export const options = (method, body) => ({
  method,
  headers: {
    "Authorization": "Bearer " + localStorage.getItem(AUTH_TOKEN),
    "Content-Type": "application/json"
  },
  body: JSON.stringify(body)
});

export const validarCuit = (cuit) => {
  if (cuit.length !== 11) {
    return false;
  }
  var acumulado = 0;
  var digitos = cuit.split("");
  var digito = parseInt(digitos.pop());
  for (var i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }
  var verif = 11 - (acumulado % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }
  return digito === verif;
};

// export const agregarPrefijo = (numero) => {
//   if (!numero || numero.length === 0) return '';
//   if (numero.startsWith("54")) {
//     numero = "+" + numero;
//   } else if (!numero.startsWith("+54")) {
//     numero = "+54" + numero;
//   }
//   return numero;
// };

export const agregarPrefijo = (numero) => {
  if (!numero || numero.length === 0) return '';

  // Eliminar cero inicial si está presente
  if (numero.startsWith("0")) {
    numero = numero.slice(1);
  }

  // Agregar prefijo
  if (numero.startsWith("54")) {
    numero = "+" + numero;
  } else if (!numero.startsWith("+54")) {
    numero = "+54" + numero;
  }

  return numero;
};

export const getDomicilio = (cliente, cliente_domicilio) => {
  const result = [];

  if (cliente_domicilio.origen === 1) { // Domicilio del cliente
    if (cliente.domiciliocomercial !== "") {
      result.push(cliente.domiciliocomercial);
    }
    if (cliente.localidad !== "") {
      result.push(", " + cliente.localidad);
    }
    if (cliente.codigopostal !== "") {
      result.push(" (" + (cliente.codigopostal).trim() + ")");
    }
  } else {
    if (cliente_domicilio.calle !== "") {
      result.push(cliente_domicilio.calle + " ");
    }
    if (cliente_domicilio.altura !== "") {
      result.push(cliente_domicilio.altura + ", ");
    }
    if (cliente_domicilio.piso !== "") {
      result.push("Piso " + cliente_domicilio.piso + ", ");
    }
    if (cliente_domicilio.depto !== "") {
      result.push("Depto " + cliente_domicilio.depto + ", ");
    }
    if (cliente_domicilio.localidad.nombre !== "") {
      result.push(cliente_domicilio.localidad.nombre);
    }
    if (cliente_domicilio.localidad.cp !== "") {
      result.push(" (" + cliente_domicilio.localidad.cp + ")");
    }
    if (cliente_domicilio.localidad.provincia.nombre !== "") {
      result.push(", " + cliente_domicilio.localidad.provincia.nombre);
    }
  }

  return result.join("");
};

export const currency = (num) => {
  if (isNaN(num)) {
    num = 0;
  }
  num = parseFloat(num);
  if (num !== "undefined" && num !== null) {
    return num.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  } else {
    return '-';
  }
};

export const labelRemitos = (e) => {
  return 'Filial de cobranza: ' + e?.cliente_ctacte?.filial?.nombre + ' - Filial de facturación: ' + e?.filial?.nombre;
};

export const onlyNumbers = (event) => {
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.', 'Backspace', 'Delete'];
  const char = event.key;

  // Check for allowed keys or valid comma/point placement:
  const currentValue = event.target.value;
  if ((char === ',' && currentValue.endsWith(' ')) ||
    (char === '.' && !currentValue.includes('.')) ||
    (allowedKeys.includes(char))) {
    // Allow input
  } else {
    event.preventDefault();
  }
};

export const estaEnCasaCentral = (usuario) => {
  return usuario?.filiales_id === 8;
};

export const getContactoPrincipal = (cliente) => {
  if (cliente && cliente.clientes_domicilios) {
    const domicilios = cliente.clientes_domicilios.find(e => e.principal === true && e.origen === TIPOS_DOMICILIOS.CLIENTE);
    if (domicilios && domicilios.clientes_domicilios_contactos) {
      const domicilios_contactos = domicilios.clientes_domicilios_contactos.find(e => e.principal === true);
      if (domicilios_contactos && domicilios_contactos.clientes_domicilios_contactos_props) {
        const domicilios_contactos_props = domicilios_contactos.clientes_domicilios_contactos_props.find(e => e.principal === true);
        if (domicilios_contactos_props) {
          return domicilios_contactos_props;
        }
      }
    }
  }

  return null;
};

export const pathIncludes = (e) => {
  return window.location.pathname.includes(e);
};

export const removeLeadingZero = (phoneNumber) => {
  if (phoneNumber.startsWith('0')) {
    return phoneNumber.slice(1);
  }
  return phoneNumber;
};

export const renderVencimiento = (vencimiento) => {
  const hoy = dayjs();

  if (vencimiento === null) {
    return 'Sin vencimiento';
  }

  const fechaVencimiento = dayjs(vencimiento);

  if (fechaVencimiento.isBefore(hoy, 'day')) {
    return (
      <Tooltip title="Vencido" mouseLeaveDelay={0}>
        <Text type="danger">{fechaVencimiento.format("DD/MM/YYYY")}</Text>
      </Tooltip>
    );
  }

  return fechaVencimiento.format("DD/MM/YYYY");
};

export const disabledDateVencimiento = (current) => {
  return current && current < moment().startOf('day');
};

export const rounded = (num) => {
  return Number(num.toFixed(2));
};

export const removeDuplicates = (data, uniqueProperty) => {
  return data.reduce((unique, item) => {
    if (!unique.some(e => e[uniqueProperty] === item[uniqueProperty])) {
      unique.push(item);
    }
    return unique;
  }, []);
}
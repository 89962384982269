import { message } from "antd"
import api from "api"
import useMutation from "hooks/useMutation"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { showErrorModal } from "utils"

const useCambiarClave = () => {
  const navigate = useNavigate();
  const usuario = useSelector(state => state.general.usuario);

  const cambiarClaveMutation = useMutation({
    mutationFn: api.auth.cambiarClave,
    onSuccess: res => {
      if (res.status !== "success") {
        message.error(`Clave actual incorrecta`)
        console.error(res.message)
        return
      }
      message.success("Clave cambiada con éxito")
      navigate("/login")
    },
    onError: err => showErrorModal({ err }),
  });

  const onFinish = values => {
    cambiarClaveMutation.mutate({
      ...values,
      version: "1.0",
      usuario: usuario.usuario
    })
  };

  return {
    onFinish
  }
};

export default useCambiarClave;
import { useNavigate } from "react-router-dom"
import api from "../api"
import useQuery from "./useQuery"
import { useDispatch, useSelector } from "react-redux"
import { generalActions } from "redux/generalSlice"

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usuario = useSelector(state => state.general.usuario);

  const { loading } = useQuery({
    queryFn: api.auth.authenticate,
    onSuccess: res => {
      dispatch(generalActions.setUsuario(res.data.usuario));
    },
    onError: err => {
      console.error("Error al autenticar el usuario: ", err)
      navigate("/login")
    },
  });

  return {
    usuario,
    loading,
  };
};

export default useAuth;
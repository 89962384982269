import { message } from "antd"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const useMutation = ({
  mutationFn,
  onSuccess,
  onError
}) => {
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const mutate = async (...args) => {
    setLoading(true)
    try {
      const data = await mutationFn(...args)
      if (data.message === "invalid or expired jwt") {
        navigate("/login")
        message.error("La sesión a expriado")
        return
      }
      if (data.status === "error") {
        throw new Error(data.message || "Error de servidor inesperado")
      }
      setData(data)
      onSuccess && onSuccess(data)
    } catch (error) {
      setError(error)
      onError && onError(error)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, data, loading, error }
}

export default useMutation
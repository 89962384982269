import { LockOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, Row } from "antd"
import { RULE_REQUIRED } from "constants"
import useCambiarClave from "./useCambiarClave"

const CambiarClave = () => {
  const { onFinish } = useCambiarClave()
  return (
    <Modal
      open
      title="Debe cambiar su clave"
      footer={false}
    >
      <Form layout="vertical" onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Clave actual"
              name="clave"
              rules={[RULE_REQUIRED]}
            >
              <Input prefix={<LockOutlined />} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Clave nueva"
              name="newclave"
              rules={[RULE_REQUIRED]}
            >
              <Input prefix={<LockOutlined />} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button type="primary" htmlType="submit" style={{ float: "right" }}>
              Cambiar clave
            </Button>
          </Col>
        </Row>


      </Form>
    </Modal>
  )
}

export default CambiarClave